export default {
  name: "AddSetor",
  data() {
    return {
      form: {
        setor: {
          transactionCodeId: "",
          userId: "",
          nominalBalance: 0,
          chartOfAccountCode: "",
        },
      },

      options: {
        userId: [],
        transactionCodeId: [],
        chartOfAccount: [],
      },
      property: {
        animation: {
          submitLoading: false,
        },
      },
    };
  },
  methods: {
    async getReferenceChartOfAccount() {
      try {
        const resp = await this.$store.dispatch({
          type: "GET_DATA_FIND_BY",
          reqUrl: "chart-of-account/list",
          endPoint: "parameter",
        });
        console.log(resp);
        if (resp.data.code === "SUCCESS") {
          resp.data.data.map((index) => {
            const text = `${index.description} - ${index.chartOfAccountCode}`;
            const value = index.chartOfAccountCode;
            this.options.chartOfAccount.push({
              text,
              value,
            });
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    routeToPageList() {
      this.$router.push("/report/collector-funding");
    },
    async getReferenceUserId() {
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "collector-funding/simple-list",
          payload: {
            userName: "",
            chartOfAccountCode: "",
            officeId: "",
            page: 0,
          },
        });
        if (resp.data.code === "SUCCESS") {
          this.options.userId = [{ value: "", text: "-- Pilih --" }];
          resp.data.data.content.map((index) => {
            const text = `${index.userCode} - ${index.userName}`;
            const value = index.userId;
            this.options.userId.push({ text, value });
          });
        }
      } catch (error) {}
    },
    helperGetTransactionCodeDataForReference() {
      this.$store
        .dispatch("GET_REFERENCE_FROM_MASTER_PARAMETER", {
          params: {
            name: "",
            page: 0,
            menuAccessid: this.fetchAppSession("@vue-session/config-bundle")
              .VUE_APP_TRX_MENU_ACCESS_COLLECTOR_FUNDING,
          },
          url: "transaction-code",
        })
        .then((response) => {
          response.data.data.content.map((i) => {
            const text = `${i.transactionCode} - ${i.transactionName}`;
            const value = i.transactionCodeId;
            const description = i.transactionName;
            const transactionCode = i.transactionCode;
            this.options.transactionCodeId.push({
              text,
              value,
              description,
              transactionCode,
            });
          });
        });
    },
    async handleAddCollectorFundingSetor() {
      var data = {
        transactionCodeId: this.form.setor.transactionCodeId,
        userId: this.form.setor.userId,
        chartOfAccountCode: this.form.setor.chartOfAccountCode,
        nominalBalance: this.form.setor.nominalBalance,
      };

      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      const confirm = await this.simpleConfirmation(
        "Simpan Data Ini ?",
        "is-success"
      );
      if (!confirm) return;
      this.property.animation.submitLoading = true;
      try {
        const resp = await this.$store.dispatch({
          type: "POST_DATA",
          endPoint: "transaction",
          reqUrl:
            "collector-funding/deposit-and-withdrawal-balance-of-collector-funding",
          payload: data,
        });
        await this.simpleWait(1500);
        if (resp.data.code === "SUCCESS") {
          const toastBuefy = this.$buefy.snackbar.open(
            this.$NotificationUtils.snackbarSuccess
          );
          toastBuefy.$on("close", () => {
            this.routeToPageList();
          });
        } else if (resp.data.code === "PLEASE_WAIT_IN_THE_PROCESS") {
          this.$buefy.dialog.alert({
            title: "Harap Tunggu !",
            message: resp.data.errorFields
              ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
              : resp.data.message,
            confirmText: "Ok",
            type: "is-danger",
          });
        } else {
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: resp.data.errorFields
              ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
              : resp.data.message,
            confirmText: "Ok",
            type: "is-danger",
          });
        }
      } catch (error) {
        await this.simpleWait(1500);
        this.$buefy.dialog.alert({
          title: "Gagal !",
          message: error.response
            ? error.response.data.errorFields
              ? this.globalNotificationErrorFieldToText(
                  error.response.data.errorFields
                )
              : error.response.statusText
            : "Terjadi Kesalahan",
          confirmText: "Ok",
          type: "is-danger",
        });
      } finally {
        await this.simpleWait(1500);
        this.property.animation.submitLoading = false;
      }
    },
  },
  mounted() {
    this.getReferenceChartOfAccount();
    this.getReferenceUserId();
    this.helperGetTransactionCodeDataForReference();
  },
};
